import {
  IDatePickerStyles,
  IDropdownOption,
  IStackItemStyles,
  ITextFieldStyles
} from '@fluentui/react'
import { IAccount } from 'api/account.types'
import { ILevelOption, ISecurity } from 'api/security.types'
import { format } from 'date-fns'
import { Dictionary, range } from 'lodash'
import {
  IDataFilter,
  NoData
} from 'shared/components/DataTableFilters/DataTableFilters'
import { IDomainItem } from 'store/context/domain'
import {
  IAccountRequests,
  IAdvisorAccountsByClientId,
  IAdvisorDashboard,
  ICustodianName,
  IFundAdmin,
  ISPAError,
  ISecurityAttributes,
  ISuppressMessageColumn,
  ISuppressMessages
} from '../../api/types'

export const textAreaStyles: Partial<ITextFieldStyles> = {
  root: {
    minWidth: 700
  },
  fieldGroup: {
    padding: '21 16',
    border: '1px solid rgba(31, 31, 31, 0.20)',
    background: '#FAFAFA',
    fontSize: 50,
    fornWeight: 400
  }
}

export const datePickerStyles: Partial<IDatePickerStyles> = {
  textField: {
    minWidth: 318,
    padding: '21 16',
    //border: '1px solid rgba(31, 31, 31, 0.20)',
    // background: '#FAFAFA',
    fontSize: 50,
    fornWeight: 400,
    paddingtop: 3
  },
  readOnlyTextField: {
    paddingTop: '3px !important'
  }
}

export const questionaryStyles = {
  backgroundColor: '#F3F1EC',
  display: 'flex',
  border: '1px solid #B49D77',
  color: '#3A3A3A',
  flexDirection: 'column',
  gridRowGap: 16,
  position: 'fixed',
  right: '1%',
  top: '70px',
  padding: '15px',
  width: 263,
  height: 143,
  marginLeft: 15,
  marginRight: 5
} as React.CSSProperties

export const stackSubTitleStyles: Partial<IStackItemStyles> = {
  root: { padding: '10px 0px', gap: 32, backgroundColor: '#84744D' }
}
export const stackContainer: Partial<IStackItemStyles> = {
  root: { paddingLeft: '15px !important' }
}

interface IPerformancePlatform {
  yes: IDropdownOption[]
  no: IDropdownOption[]
}

export const performancePlatformList: IDropdownOption[] = [
  {
    key: 'Addepar',
    text: 'Addepar'
  },
  {
    key: 'Tamarac',
    text: 'Tamarac'
  }
]
export const custodianNameOptionList: IDropdownOption[] = [
  {
    key: 'OHL',
    text: 'Outside Holding'
  }
]
export const assetAllocations: string[] = ['P', 'H']
export const promissoryNotes: string[] = ['PNRR']

export const fixedIncome: string[] = ['F']

export const platformList: IPerformancePlatform = {
  yes: [
    {
      key: 'GWES',
      text: 'GWES'
    },
    {
      key: 'Addepar / GWES',
      text: 'Addepar / GWES'
    },
    {
      key: 'Tamarac / GWES',
      text: 'Tamarac / GWES'
    }
  ],
  no: [
    {
      key: 'Addepar',
      text: 'Addepar'
    },
    {
      key: 'Tamarac',
      text: 'Tamarac'
    }
  ]
}

export const yesNoList: IDropdownOption[] = [
  {
    key: 'Yes',
    text: 'Yes'
  },
  {
    key: 'No',
    text: 'No'
  }
]

export const vendorList: IDropdownOption[] = [
  {
    key: 'Mirador',
    text: 'Mirador'
  },
  {
    key: 'FSA',
    text: 'FSA'
  },
  {
    key: 'RCM_SA_Team',
    text: 'RCM SA Team'
  }
]

export const accountRegistrationsList: IDropdownOption[] = [
  { key: 'COMP', text: 'Joint - Community Property' },
  { key: 'CP', text: 'Corporation' },
  { key: 'I', text: 'Individual' },
  { key: 'IC', text: 'Investment Club' },
  { key: 'J', text: 'Joint - with Right of Survivorship' },
  { key: 'LLC', text: 'Limited Liability Company' },
  { key: 'NP', text: 'Non-Prototype / Corporate Trust' },
  { key: 'PT', text: 'Partnership' },
  { key: 'SP', text: 'Sole Proprietorship' },
  { key: 'TIC', text: 'Joint - Tenants in Common' },
  { key: 'TIE', text: 'Joint - Tenants in Entirety' },
  { key: 'TODE', text: 'Transfer on Death - Tenants in Entirety' },
  { key: 'TODI', text: 'Transfer on Death - Individual' },
  { key: 'TODJ', text: 'Transfer on Death - Joint WRS' },
  { key: 'TRUA', text: 'Trust - Under Agreement' },
  { key: 'TRUW', text: 'Trust - Under Will' },
  { key: 'UA', text: 'Unincorporated Association' },
  { key: 'UF', text: 'Joint - Usufruct' }
]

export const taxStatusList: IPerformancePlatform = {
  yes: [
    {
      key: 'Tax-Deferred',
      text: 'Tax-Deferred'
    },
    {
      key: 'Tax-Exempt',
      text: 'Tax-Exempt'
    },
    {
      key: 'Taxable',
      text: 'Taxable'
    }
  ],
  no: yesNoList
}

export const basisList: IDropdownOption[] = [
  {
    key: 'Accrual Basis',
    text: 'Accrual Basis'
  },
  { key: 'Cash Basis', text: 'Cash Basis' }
]
export const AccrualBasisOptions: IDropdownOption[] = [
  {
    key: 'Actual by 360',
    text: 'Actual by 360'
  },
  {
    key: 'Actual by 365',
    text: 'Actual by 365'
  },
  {
    key: 'Actual by Actual',
    text: 'Actual by Actual'
  },
  {
    key: 'Thirty by 360',
    text: 'Thirty by 360'
  }
]
export const PaymentFrequencyOptions: IDropdownOption[] = [
  {
    key: 'Annual',
    text: 'Annual'
  },
  {
    key: 'Monthly',
    text: 'Monthly'
  },
  {
    key: 'Quarterly',
    text: 'Quarterly'
  },
  {
    key: 'Semi-Annual',
    text: 'Semi-Annual'
  }
]
export const cashPaymentFrequencyOptions: IDropdownOption[] = [
  {
    key: 'At Maturity',
    text: 'At Maturity'
  }
]

export const ausBucketList: IDropdownOption[] = [
  {
    key: 'Managed',
    text: 'Managed'
  },
  {
    key: 'Advised Only',
    text: 'Advised Only'
  },
  {
    key: 'Brokerage',
    text: 'Brokerage'
  },
  {
    key: 'Reporting Only',
    text: 'Reporting Only'
  }
]

export const onFormatDate = (date?: Date | undefined): string => {
  return date ? format(new Date(date), 'MM/dd/yyyy') : ''
}

const years = range(2020, new Date().getFullYear() + 1)

export const VintageOptions = [
  { key: 0, text: 'All' },
  { key: 2019, text: '2018-2019' }
]

VintageOptions.push(
  ...years.map((year) => ({ key: year, text: year.toString() }))
)

export const isDirectOwnerEnable = (key?: string) => {
  return key?.includes('Addepar') ? true : false
}

export const isTamarac = (key?: string) => {
  return key?.includes('Tamarac') ? true : false
}

export const isAccrualBasis = (key?: string) => {
  return key?.includes('Accrual Basis') ? true : false
}
export const isCashBasis = (key?: string) => {
  return key?.includes('Cash Basis') ? true : false
}
export function getEnumKeyByEnumValue<T extends { [index: string]: string }>(
  myEnum: T,
  enumValue: string
): keyof T | null {
  const keys = Object.keys(myEnum).filter((x) => myEnum[x] === enumValue)
  return keys.length > 0 ? keys[0] : null
}

export const getClientOptions = (
  clientSearchResults?: any[],
  clientId?: string,
  clientName?: string
) => {
  const clientData: IDropdownOption[] | undefined = clientSearchResults?.map(
    (x: any) =>
      ({
        key: x.LegalEntityID,
        text: x.LegalEntityName
      } as IDropdownOption)
  )
  return clientSearchResults?.find((x: any) => x.LegalEntityID === clientId) ===
    undefined &&
    clientId !== undefined &&
    clientName !== undefined &&
    clientData
    ? [
        {
          key: clientId as string,
          text: clientName
        } as IDropdownOption
      ].concat(clientData)
    : clientData
}

export const getAdvisorOptions = (
  advisorSearchResults?: IDomainItem[],
  advisorId?: string,
  advisorName?: string
) => {
  const advisorData: IDropdownOption[] | undefined = advisorSearchResults?.map(
    (x: any) =>
      ({
        key: x.ClientAdvisorID,
        text: x.ClientAdvisorID + ' - ' + x.ClientAdvisor
      } as IDropdownOption)
  )
  return advisorSearchResults?.find(
    (x: any) => x.ClientAdvisorID === advisorId
  ) === undefined &&
    advisorId !== undefined &&
    advisorName !== undefined &&
    advisorData
    ? [
        {
          key: advisorId as string,
          text: advisorId + ' - ' + advisorName
        } as IDropdownOption
      ].concat(advisorData)
    : advisorData
}

export const getSecurityOptions = (
  securitySearchResults?: ISecurity[],
  securityId?: string,
  securityDesc?: string
) => {
  const securityData: IDropdownOption[] | undefined =
    securitySearchResults?.map(
      (x: any) =>
        ({
          key: x.id,
          text: x.securitydescription
        } as IDropdownOption)
    )
  return securitySearchResults?.find((x: any) => x.id === securityId) ===
    undefined &&
    securityId !== undefined &&
    securityDesc !== undefined &&
    securityData
    ? [
        {
          key: securityId,
          text: securityDesc
        } as IDropdownOption
      ].concat(securityData)
    : securityData
}

export const getCustodianNameOptions = (
  accountSearchResults?: IAccount[],
  custodianName?: string
) => {
  const accountData: IDropdownOption[] | undefined = unique(
    accountSearchResults?.map((x) => x.CustodianName)
  )?.map(
    (x: any) =>
      ({
        key: x.toUpperCase(),
        text: x.toUpperCase()
      } as IDropdownOption)
  )

  return accountSearchResults?.find(
    (x: any) => x.CustodianName === custodianName
  ) === undefined &&
    custodianName !== undefined &&
    accountData
    ? [
        {
          key: custodianName,
          text: custodianName
        } as IDropdownOption
      ].concat(accountData as IDropdownOption[])
    : accountData
}

export const getCustodianAccountOptions = (
  accountSearchResults?: IAccount[],
  custodyAccount?: string,
  distCustodian?: string
) => {
  const accountData: IDropdownOption[] | undefined = accountSearchResults
    ?.filter((cc) => cc.CustodianName?.toUpperCase() === distCustodian)
    ?.map(
      (x: any) =>
        ({
          key: x.CustodyAccount,
          text: x.CustodyAccount
        } as IDropdownOption)
    )
  return accountSearchResults?.find(
    (x: any) =>
      x.CustodyAccount === custodyAccount && x.CustodianName === distCustodian
  ) === undefined &&
    custodyAccount !== undefined &&
    accountData
    ? [
        {
          key: custodyAccount,
          text: custodyAccount
        } as IDropdownOption
      ].concat(accountData)
    : accountData
}

const unique = (arr: any) => {
  const ddd = new Set(arr) as any
  return [...ddd]
}

export enum SPAStatus {
  DRAFT = 'Draft',
  ACTIVE = 'Approved',
  PNDRVW = 'Pending Review',
  RETRND = 'Returned',
  INACTV = 'Inactive',
  NULL = 'NULL',
  COMPLETE = 'Complete'
}

export enum AccountSource {
  FSA,
  Tamarac,
  Addepar,
  SATool
}
export const accountSourceArray: string[] = Object.keys(AccountSource).filter(
  (key) => isNaN(Number(key))
)
export enum NonSPAAccountSource {
  NFS = 'NFS',
  FirstRate = 'First Rate'
}
export const nonSPAAccountSourceArray: string[] =
  Object.values(NonSPAAccountSource)

export const filterDashboardByFilterData = (
  assetsList: IAdvisorDashboard[],
  filterBy: Dictionary<IDataFilter>
) => {
  let filteredData: IAdvisorDashboard[] = [...assetsList]
  if (filterBy) {
    Object.entries(filterBy)?.map(([key, data]) => {
      filteredData = filteredData.filter((x) => {
        if (data?.value) {
          return (data.value as (string | number)[]).includes(
            (x[key as keyof IAdvisorDashboard] as string | number) || NoData
          )
        } else {
          return true
        }
      })
    })
  }
  return filteredData
}

export const filterDashboardBySearchtext = (
  items: IAdvisorDashboard[],
  searchText: string
) => {
  let filtered: IAdvisorDashboard[] = items || []
  if (items && searchText && Array.isArray(items)) {
    const lowerCaseText = searchText.toLowerCase()
    filtered = items.filter((p) => {
      if (
        p?.clientName?.toLowerCase()?.includes(lowerCaseText) ||
        formatDateString(p?.submittedDate)?.startsWith(searchText) ||
        p?.requestor?.toLowerCase()?.includes(lowerCaseText) ||
        p?.repCodeName?.toLowerCase()?.includes(lowerCaseText) ||
        p?.rtc?.toLowerCase()?.includes(lowerCaseText) ||
        p?.requestID?.toFixed(2)?.toString()?.startsWith(searchText) ||
        p?.partyId?.toLowerCase()?.includes(lowerCaseText) ||
        p?.investmentName?.toLowerCase()?.includes(lowerCaseText) ||
        p?.platform?.toLowerCase()?.includes(lowerCaseText) ||
        p?.commitment?.toString()?.startsWith(searchText) ||
        p?.requestStatus?.toLowerCase()?.includes(lowerCaseText)
      ) {
        return true
      } else {
        return false
      }
    })
  }
  return filtered
}

export const dateFormat = (date: Date) =>
  new Date(date)?.toLocaleDateString('en-US')

export const convertToUTC = (
  dateString: string | undefined
): Date | undefined => {
  if (!dateString) {
    return undefined
  }
  const localDate = new Date(dateString)
  const utcDate = new Date(localDate.getTime() + 5 * 60 * 60 * 1000)
  return utcDate
}

export const getDefaultLevelOptions = (
  code: 'l1Code' | 'l2Code' | 'l3Code' | 'l4Code' | 'minorAssetType',
  selectedRecord?: ISecurityAttributes
) => {
  switch (code) {
    case 'l1Code':
      return [
        { key: selectedRecord?.l1Code, text: selectedRecord?.l1Description }
      ] as IDropdownOption[]
    case 'l2Code':
      return [
        { key: selectedRecord?.l2Code, text: selectedRecord?.l2Description }
      ] as IDropdownOption[]
    case 'l3Code':
      return [
        { key: selectedRecord?.l3Code, text: selectedRecord?.l3Description }
      ] as IDropdownOption[]
    case 'l4Code':
      return [
        { key: selectedRecord?.l4Code, text: selectedRecord?.l4Description }
      ] as IDropdownOption[]
    case 'minorAssetType':
      return [
        {
          key: selectedRecord?.minorAssetType,
          text: selectedRecord?.minorAssetType
        }
      ] as IDropdownOption[]
  }
}

const getDistinctOptions = (unfilteredRecords: any[]) => {
  return unfilteredRecords.filter(
    (obj: { key: any }, index: any, self: any[]) =>
      index === self.findIndex((record: { key: any }) => record.key === obj.key)
  )
}

export const getLevelOptions = (
  code: 'l1Code' | 'l2Code' | 'l3Code' | 'l4Code',
  description:
    | 'l1Description'
    | 'l2Description'
    | 'l3Description'
    | 'l4Description',
  levelOptions: ILevelOption[],
  record?: ISecurityAttributes
) => {
  let dropdownOptions = [...levelOptions]
  let optionValue, codeValue
  switch (code) {
    case 'l2Code':
      optionValue = record?.l1Code
      codeValue = 'l1Code'
      break
    case 'l3Code':
      optionValue = record?.l2Code || record?.l1Code
      codeValue = record?.l2Code ? 'l2Code' : 'l1Code'
      break
    case 'l4Code':
      optionValue = record?.l3Code || record?.l2Code || record?.l1Code
      codeValue = record?.l3Code
        ? 'l3Code'
        : record?.l2Code
        ? 'l2Code'
        : 'l1Code'
      break
  }
  if (codeValue && optionValue) {
    dropdownOptions = filterArrayByAttribute(
      levelOptions,
      codeValue,
      optionValue
    )
  }
  const distinctOptions = dropdownOptions.map((obj: ILevelOption) => {
    return { key: obj[code], text: obj[description] }
  })
  return getDistinctOptions(distinctOptions)
}

export const filterArrayByAttribute = (
  records: any[],
  attr: string | number,
  value: any
) => {
  return records.filter((obj: { [x: string]: any }) => obj[attr] === value)
}
export const formatDateString = (date: string | undefined): string => {
  const formattedString = ''
  try {
    if (date) {
      return format(new Date(date || ''), 'MM/dd/yyyy')
    }
  } catch {
    return formattedString
  }
  return formattedString
}

export const getAccCustodianOptions = (custList?: ICustodianName[]) => {
  const dropdownOptions =
    custList?.map(
      (x: any) =>
        ({
          key: x.code,
          text: x.name
        } as IDropdownOption)
    ) || []
  dropdownOptions.push({
    key: 'Manual',
    text: 'Manual'
  })
  dropdownOptions.unshift({
    key: '',
    text: 'Select'
  })
  return dropdownOptions
}

export const getExistedAccNoOptions = (
  accountNumberList?: IAccount[],
  advisorAccountByClientId?: IAdvisorAccountsByClientId[]
) => {
  const accNoList1 =
    accountNumberList?.map(
      (x: any) =>
        ({
          key: x.CustodyAccount,
          text: x.CustodyAccount
        } as IDropdownOption)
    ) || []

  const accNoList2 =
    advisorAccountByClientId?.map(
      (x: any) =>
        ({
          key: x.accNo,
          text: x.accNo
        } as IDropdownOption)
    ) || []

  const uniqueValues = [...new Set([...accNoList1, ...accNoList2])]

  return uniqueValues
}

export const getCustodianAccNoOptions = (accountNumberList?: IAccount[]) => {
  return accountNumberList?.map(
    (x: any) =>
      ({
        key: `${x.CustodyAccount} - ${x.CustodianName}`,
        text: `${x.CustodyAccount} - ${x.CustodianName}`
      } as IDropdownOption)
  )
}
export const getFundAdminOptions = (fundNameList?: IFundAdmin[]) => {
  return fundNameList?.map(
    (x: any) =>
      ({
        key: `${x.fundName}`,
        text: `${x.fundName}`
      } as IDropdownOption)
  )
}

export const convertToUSD = (data: number | undefined): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })

  return data ? formatter.format(data) : '$0'
}

export const filterPlaformErrorByFilterData = (
  assetsList: ISPAError[],
  filterBy: Dictionary<IDataFilter>
) => {
  let filteredData: ISPAError[] = [...assetsList]
  if (filterBy) {
    Object.entries(filterBy)?.map(([key, data]) => {
      filteredData = filteredData.filter((x) => {
        if (data?.value) {
          return (data.value as (string | number)[]).includes(
            (x[key as keyof ISPAError] as string | number) || NoData
          )
        } else {
          return true
        }
      })
    })
  }
  return filteredData
}

export const filterPlaformErrorySearchtext = (
  items: ISPAError[],
  searchText: string
) => {
  let filtered: ISPAError[] = items || []
  if (items && searchText && Array.isArray(items)) {
    const lowerCaseText = searchText.toLowerCase()
    filtered = items.filter((p) => {
      if (
        p?.errorID?.toLowerCase()?.includes(lowerCaseText) ||
        p?.entity?.toLowerCase()?.includes(lowerCaseText) ||
        p?.entityID?.toLowerCase()?.includes(lowerCaseText) ||
        p?.sourceName?.toLowerCase()?.includes(lowerCaseText) ||
        p?.accNo?.toLowerCase()?.includes(lowerCaseText) ||
        p?.cusip?.toLowerCase()?.includes(lowerCaseText) ||
        p?.refAcc?.toLowerCase()?.includes(lowerCaseText) ||
        p?.custodian_Cusip?.toLowerCase()?.includes(lowerCaseText) ||
        p?.owner_Id?.toLowerCase()?.includes(lowerCaseText) ||
        p?.owned_Id?.toLowerCase()?.includes(lowerCaseText) ||
        p?.sourceSecurityID?.toLowerCase()?.includes(lowerCaseText) ||
        p?.symbol?.toLowerCase()?.includes(lowerCaseText) ||
        p?.message?.toLowerCase()?.includes(lowerCaseText) ||
        p?.status?.toLowerCase()?.includes(lowerCaseText)
      ) {
        return true
      } else {
        return false
      }
    })
  }
  return filtered
}

export const getSPAErrorData = (data: ISPAError[]) => {
  const errorData: ISPAError[] | [] = data?.map(
    (x: any) =>
      ({
        errorID: x.errorID,
        entity: x.entity,
        entityID: x.entityID,
        sourceName: x.sourceName,
        age: x.age,
        message: x.message,
        status: x.status,
        updatedDate: x.updatedDate,
        ...getEntityData(x.entity, x.entityID)
      } as ISPAError)
  )
  return errorData
}

const getEntityData = (entity: string, entityID: string) => {
  switch (entity) {
    case 'Account':
      return {
        accNo: entityID.split('|')?.[0],
        refAcc: entityID.split('|')?.[1]
      }
    case 'Security':
      return {
        cusip: entityID.split('|')?.[0],
        symbol: entityID.split('|')?.[1],
        custodian_Cusip: entityID.split('|')?.[2],
        sourceSecurityID: entityID.split('|')?.[3]
      }
    case 'Commitment':
      return {
        accNo: entityID.split('|')?.[0],
        cusip: entityID.split('|')?.[1],
        refAcc: entityID.split('|')?.[2],
        custodian_Cusip: entityID.split('|')?.[3],
        owner_Id: entityID.split('|')?.[4],
        owned_Id: entityID.split('|')?.[5]
      }
    case 'Activity':
      return {
        accNo: entityID.split('|')?.[0],
        cusip: entityID.split('|')?.[1],
        owner_Id: entityID.split('|')?.[2],
        owned_Id: entityID.split('|')?.[3],
        tradeDate: entityID.split('|')?.[6]
      }
    default:
      return {
        accNo: entityID.split('|')?.[0],
        cusip: entityID.split('|')?.[1],
        refAcc: entityID.split('|')?.[2],
        custodian_Cusip: entityID.split('|')?.[3],
        direct_Owner_Id: entityID.split('|')?.[4],
        owned_Id: entityID.split('|')?.[5],
        sourceSecurityID: entityID.split('|')?.[6],
        owner_Id: entityID.split('|')?.[7]
      }
  }
}

export const filterCRMAccountByFilterData = (
  assetsList: IAccountRequests[],
  filterBy: Dictionary<IDataFilter>
) => {
  let filteredData: ISPAError[] = [...assetsList]
  if (filterBy) {
    Object.entries(filterBy)?.map(([key, data]) => {
      filteredData = filteredData.filter((x) => {
        if (data?.value) {
          return (data.value as (string | number)[]).includes(
            (x[key as keyof ISPAError] as string | number) || NoData
          )
        } else {
          return true
        }
      })
    })
  }
  return filteredData
}

export const filterSuppressMessageByFilterData = (
  assetsList: ISuppressMessageColumn[],
  filterBy: Dictionary<IDataFilter>
) => {
  let filteredData: ISuppressMessages[] = [...assetsList]
  if (filterBy) {
    Object.entries(filterBy)?.map(([key, data]) => {
      filteredData = filteredData.filter((x) => {
        if (data?.value) {
          return (data.value as (string | number)[]).includes(
            (x[key as keyof ISuppressMessages] as string | number) || NoData
          )
        } else {
          return true
        }
      })
    })
  }
  return filteredData
}

export const filterCRMAccountSearchtext = (
  items: IAccountRequests[],
  searchText: string
) => {
  let filtered: ISPAError[] = items || []
  if (items && searchText && Array.isArray(items)) {
    const lowerCaseText = searchText.toLowerCase()
    filtered = items.filter((p) => {
      if (
        p?.accountNumber?.toLowerCase()?.includes(lowerCaseText) ||
        p?.uniqueId?.toLowerCase()?.includes(lowerCaseText) ||
        p?.status?.toLowerCase()?.includes(lowerCaseText)
      ) {
        return true
      } else {
        return false
      }
    })
  }
  return filtered
}

export const filterSuppressMessageSearchtext = (
  items: ISuppressMessageColumn[],
  searchText: string
) => {
  let filtered: ISuppressMessages[] = items || []
  if (items && searchText && Array.isArray(items)) {
    const lowerCaseText = searchText.toLowerCase()
    filtered = items.filter((p) => {
      if (
        p?.errorMessage?.toLowerCase()?.includes(lowerCaseText) ||
        p?.updatedBy?.toLowerCase()?.includes(lowerCaseText) ||
        p?.count?.toString().toLowerCase()?.includes(lowerCaseText)
      ) {
        return true
      } else {
        return false
      }
    })
  }
  return filtered
}
